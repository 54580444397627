.wrap.container {
  max-width: 750px;
}

main {
  padding-top: 20px;
}

body.home {
  main {
	padding-top: 75px;
  }
}

body.engagement {

  background: #f1f1f1;

  .wrap {

	@media (min-width: $screen-lg-min) {
	  width:     100%;
	  padding:   0;
	  max-width: none;
	}

	@media (min-width: $screen-xl-min) {
	  max-width: $container-xl;
	  //padding-left:  ($grid-gutter-width / 2);
	  //padding-right: ($grid-gutter-width / 2);
	}

	.content {

	  @media (min-width: $screen-lg-min) {
		margin: 0;
	  }

	}

	main {

	  @media (min-width: $screen-lg-min) {
		padding: 0;
	  }

	}

  }

  .page-header {

	@media (min-width: $screen-lg-min) {
	  display: none;
	}

  }

  h2 {
	font-family: 'Open Sans', sans-serif;
	color:       #fff;
  }

  h3 {
	font-family:    'Source Serif Pro', serif;
	font-size:      36px;
	color:          $brand-primary;
	text-transform: none;
	margin-bottom:  30px;
	line-height:    45px;
  }

}

.panel-heading {
  position: relative;
  padding:  0;

  .arrow-down {

	top: 25px;

	@media (min-width: $screen-sm-min) {
	  right:  30px;
	  width:  40px;
	  height: 40px;
	  top:    55px;
	}

	&:after, &:before {

	  @media (min-width: $screen-sm-min) {
		width: 3px;
	  }

	}

	&.collapsed {

	  top: 28px;

	  @media (min-width: $screen-sm-min) {
		top: 58px;

		&:before {
		  left: 12px;
		}

		&:after {
		  left: -15px;
		}

	  }

	}

  }

}

.panel-title {
  font-size: xx-large;

  height:    100%;
  left:      0;
  position:  absolute;
  top:       0;
  width:     100%;
  z-index:   3;

  @media (min-width: $screen-sm-min) {
	font-size: 48px;
  }

  a {
	display:     block;
	padding:     10px 15px;
	height:      100%;
	line-height: 50px;
	color:       #fff;;
	font-weight: 700;

	@media (min-width: $screen-sm-min) {
	  padding-top:  50px;
	  padding-left: 30px;
	}

	&:hover, &:focus {
	  opacity: 1;
	}

  }

}

.timeline-posts {

  margin-top: 30px;

  li {
	border-top: 1px solid #e6e6e6;

  }

  li > a {
	padding:     12px 0;
	font-size:   16px;
	position:    relative;
	line-height: 1.3;
	display:     block;

	&:after {
	  top:      14px;
	  right:    0;
	  content:  "";
	  position: absolute;
	  @include cssSprite($arrow-link-diagonal);
	}

  }

}

.panel-background {
  max-width: 100%;

  @media (min-width: $screen-sm-min) {
	width: 100%;
  }

}

.frame-closed {

  .dark-overlay {

	background: rgba(14, 35, 86, 0.4);
	position:   absolute;
	left:       0;
	top:        0;
	height:     100%;
	width:      100%;
	z-index:    1;
  }

}

.frame-open {

  .dark-overlay {

	background: none;

  }

}

#accordion {

  .panel + .panel {
	margin-top: 10px;
  }

  .panel-heading {

	.arrow-down {
	  float:    none;
	  position: absolute;
	}

	h2 {
	  text-transform: uppercase;
	}

  }

  .dark-overlay {
	background: rgba(14, 35, 86, 0.4);
	height:     100%;
	left:       0;
	position:   absolute;
	top:        0;
	width:      100%;
  }

}

#timeline {

  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  margin-top: -180px;

  #featured {
	margin-bottom: 0;
  }

  img {
	height: 100%;
  }

  .title {
	position:    absolute;
	transform:   rotate(270deg);
	margin-top:  40px;
	margin-left: 14px;
	color:       #fff;
	z-index:     10;

	h2 {
	  text-transform: uppercase;

	  @media (min-width: $screen-md-min) {
		font-size: 38px;
	  }

	  @media (min-width: $screen-lg-min) {
		font-size: 48px;
	  }

	  &:before {
		@include cssSprite($arrow-right);
		content:   "";
		top:       160px;
		right:     50px;
		position:  absolute;
		transform: rotate(90deg);
		right:     610px;
		top:       35px;
	  }

	}
  }

  .frame {

	box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);

	img {

	  transition: all 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000);

	  :before {
		content:    "";
		position:   absolute;
		left:       0;
		top:        0;
		background: rgba(0, 0, 0, 0.5);
	  }

	}

	&.frame-closed {

	  &:hover {

		img {
		  transform: scale(1.04);
		}

	  }
	}

	&:last-of-type {

	  .title {

		margin-left: 20px;

		h2 {
		  font-size:   36px;
		  margin-left: -18px;

		  &:before {
			right: 602px;
		  }
		}

	  }

	}

  }

  .frame-content {
	padding:    30px 150px 50px 50px;
	left:       66px;
	position:   absolute;
	top:        0;
	right:      0;
	bottom:     0;
	z-index:    2;
	background: #fff;
	transition: left 0.25s cubic-bezier(0.215, 0.610, 0.355, 1.000);

	p.lead {

	  @media (min-width: $screen-lg-min) {
		font-size:   24px;
		line-height: 32px;
	  }

	}

	@media (min-width: $screen-lg-min) {
	  left: 15%;
	}

	@media (min-width: $screen-xl-min) {
	  left: 130px;
	}

  }

  .frame-open {

	.frame-content {

	  @media (min-width: $screen-xl-min) {
		left: 150px;
	  }

	}

  }
}

.timeline-mark {

  position: absolute;
  top:      0;
  display:  block;
  width:    200px;

  &:before {
	content:    "";
	position:   absolute;
	background: $brand-primary;
	height:     40px;
	width:      12px;
	top:        0;
  }

  span, h1 {
	margin-left:    20px;
	position:       absolute;
	margin-top:     24px;
	text-transform: uppercase;
	font-weight:    bold;
	font-size:      16px;
	color:          #333;
	line-height:    1;
  }

}

.page-header {
  margin:  0;
  padding: 0;
  border:  none;
}

#fullwidth-slider {
  background-repeat:   no-repeat;
  background-size:     cover;
  position:            relative;
  margin-bottom:       20px;
  background-position: top center;

  @media (min-width: $screen-md-min) {
	max-height: 100%;
  }

  .caption {
	left:         0;
	margin-left:  auto;
	margin-right: auto;
	position:     absolute;
	right:        0;
	text-align:   center;
	width:        280px;
	z-index:      2;
	top:          35%;
	transform:    translateY(-40%);

	color:        #fff;

	@media (min-width: $screen-sm-min) {
	  width: 600px;
	}

	@media (min-width: $screen-md-min) {
	  width: 730px;
	}

	h1 {
	  margin-top: 0;
	  hyphens:    auto;
	}

	.lead {
	  @extend h3;
	  margin-top: 20px;
	}

  }

  .arrow-down-white {
	position: absolute;
	bottom:   30px;
	width:    100%;
	z-index:  2;

	@media (min-width: $screen-sm-min) {
	  bottom: 60px;
	}
  }

}

#header-image {
  height:              200px;
  background-repeat:   no-repeat;
  background-size:     cover;
  background-position: center;
  //max-width: $container-xl;
  margin-left:         auto;
  margin-right:        auto;

  @media (min-width: $screen-sm-min) {
	height: 300px;
  }

  @media (min-width: $screen-md-min) {
  }

  @media (min-width: $screen-lg-min) {
	height: 400px;
  }

  .trans-bg-white {
	width:            100%;
	height:           100%;
	background-color: rgba(255, 255, 255, 0.25);
  }

}

.teaser {

  a {
	color:       #333;
	font-weight: 300;

	&:hover, &:focus {
	  opacity: 1;

	  @media (min-width: $screen-lg-min) {

		.teaser-element {
		  transform:  translateY(-1px);
		  box-shadow: 2px 2px 4px 0px rgba(51, 51, 3, 0.4);
		}

	  }

	}

  }

  .teaser-element {
	background:    #f2f2f2;
	box-shadow:    1px 1px 3px 0px rgba(51, 51, 3, 0.4);
	transition:    all 0.2s ease-out;
	margin-bottom: 30px;
  }

  .teaser-element-info {
	padding:  1px 15px 10px;
	position: relative;

	@media (min-width: $screen-sm-min) {
	  min-height: 120px;
	}

	h4 {
	  @media (min-width: $screen-sm-min) {
		//font-size: 18px;
	  }
	}

	.arrow {

	  position: absolute;

	  bottom:   18px;
	  right:    15px;

	  @media (min-width: $screen-sm-min) {
		bottom: 15px;
	  }

	  &.arrow-diagonal {
		@include cssSprite($arrow-link-diagonal);
	  }

	  &.arrow-right {
		@include cssSprite($arrow-right-small-blue);
	  }

	}

  }

}

.team-position {
  margin-bottom: 0;
  color:         $brand-secondary;
}

.chartWithOverlay {
  position:       relative;

  padding-bottom: 50px;

  @media (min-width: $screen-sm-min) {
	padding-bottom: 80px;
  }

}

.overlay {
  width:       100%;
  font-weight: normal;
  font-size:   18px;
  text-align:  center;
  position:    absolute;
  top:         130px;
  line-height: 1;

  @media (min-width: $screen-sm-min) {
	top:         240px;
	font-size:   20px;
	line-height: 1.2;
  }

}

.chart {
  width:      100%;
  height:     250px;
  min-height: 0;

  @media (min-width: $screen-sm-min) {
	min-height: 450px;
  }

}

.order-prospect {
  margin-top:    75px;
  margin-bottom: 30px;
  img {
	margin-top:    5px;
	margin-bottom: 15px;
	width:         200px;
	box-shadow:    0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  h3 {
	margin-top: 0;
  }
}