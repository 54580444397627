footer {

	font-size: 14px;
	padding-top: 20px;
	padding-bottom: 5px;
	color: #999999;

	@media (min-width: $screen-sm-min) {
		margin-top: 20px;
	}

	@media (min-width: $screen-md-min) {
		padding: 10px 30px 0;
	}

	.footer-content {

		ul {

			> li {
				@media (min-width: $screen-sm-min) {
					display: inline-block;
    				padding-left: 5px;
    				padding-right: 5px;
				}

			}

		}

		.scrollToLink {

			position: relative;
			padding-right: 20px;

			&:after {
				@include cssSprite($arrow-top-small-gray);
				content:"";
				position: absolute;
				right: 0;
				top: 2px;
			}

		}

	}

}

body:not(.engagement) footer {

	border-top: 1px solid #e7e7e7;
	background: #f2f2f2;

}