body {
  //background: $background-color;
  font-family:             'Open Sans', sans-serif;
  color:                   #333333;
  font-size:               18px;
  line-height:             1.6;
  font-weight:             400;
  line-height:             30px;
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing:          antialiased;
}

a {
  outline:     0 !important;
  color:       #0088cc;
  font-weight: normal;

  &:hover, &:focus {
	color:   #0088cc;
	opacity: 0.7;
  }
}

h2, .caption h1 {
  font-family: 'Source Serif Pro', serif;
  font-style:  normal;
  font-weight: 600;
  color:       $brand-primary;
  @media (min-width: $screen-sm-min) {
	font-size:   44px;
	line-height: 50px;
  }

}

h1, h2, h3, h4 {
  font-weight: 600;
}

h3 {
  font-weight:    400;
  color:          $brand-secondary;
  font-size:      18px;
  text-transform: uppercase;
  margin-top:     40px;
  margin-bottom:  15px;
  line-height:    30px;

}

h4 {
  font-size:  18px;
  color:      $brand-secondary;
  margin-top: 24px;
}

a:hover, a:focus {
  text-decoration: none;
}

ol, ul {
  padding-left: 18px;

  &.link-list {
	list-style:   none;
	padding-left: 0;

	li {
	  position:     relative;
	  padding-left: 32px;
	}

	li > a:before {
	  content:  "";
	  position: absolute;
	  left:     0;
	  top:      7px;
	  @include cssSprite($arrow-right-small-blue);
	}

  }

}

main {

  @media (min-width: $screen-sm-min) {
	padding-top:    30px;
	padding-bottom: 80px;
  }

  h2 {

	@media (min-width: $screen-sm-min) {

	  margin-bottom: 40px;

	}

  }

}

.pre-title {
  font-size:      18px;
  font-style:     normal;
  font-weight:    400;
  color:          $brand-secondary;
  text-transform: uppercase;
}

.lead {
  font-family: 'Source Serif Pro', serif;
  color:       $brand-secondary;
  font-style:  normal;
  padding:     0;
  font-size:   20px;
  font-weight: 400;
  @media (min-width: $screen-sm-min) {
	line-height: 40px;
	font-size:   30px;
	margin:      -15px 0 1.038em 0;
  }
}

.content {
  padding-bottom: 30px;
}

.modal-dialog {

  @media (min-width: $screen-sm-min) {
	margin: 80px auto;
  }

  @media (min-width: $screen-md-min) {
	//width: 800px;
  }

}

.modal-header {
  background: none;
  color:      #fff;
  border:     none;

  .close {
	margin-top: 4px;
  }
}

.modal-body {
  @media (min-width: $screen-sm-min) {
	padding: 40px 20px;
  }

  @media (min-width: $screen-md-min) {
	padding: 40px 100px 100px 100px;

	.team-info {
	  margin-bottom: 25px;
	}

	img {
	  margin-bottom: 30px;
	}

  }

  @media (min-width: $screen-lg-min) {
	padding: 60px 90px;
  }
}

.modal-content {
  border-radius:    0;
  box-shadow:       none;
  border:           0;
  background-color: transparent;
}

.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.in {
  opacity: 1;

}

.close-cross, .arrow-down {
  height:     26px;
  width:      26px;
  position:   relative;
  opacity:    1;
  z-index:    4;
  float:      right;
  transition: all 0.3s ease 0.3s;
  right:      0;
  top:        0;

  &:before, &:after {
	right:            13px;
	position:         absolute;
	content:          ' ';
	height:           100%;
	width:            2px;
	background-color: #fff;
  }

}

.close-cross {

  &:before, &:after {
	right: 21px;
  }

  &:before {
	transform: rotate(45deg);
  }

  &::after {
	transform: rotate(-45deg);
  }

  &.close-cross-lg {
	height: 42px;
	width:  42px;
  }

  &.close-cross-invert {

	&:before, &:after {
	  background-color: #0088cc;
	}

  }

}

.arrow-down {

  &.collapsed {
	height: 20px;
	width:  26px;

  }

  &:before {
	transform:  rotate(45deg);
	transition: all 0.3s ease 0.3s;
	left:       0;
  }

  &.collapsed:before {
	left:      7px;
	transform: rotate(45deg);
  }

  &:after {
	transform:  rotate(-45deg);
	transition: all 0.3s ease 0.3s;
	left:       0;
  }

  &.collapsed:after {
	left:      -7px;
	transform: rotate(-45deg);
  }
}

.panel-body {
  position:    relative;
  padding-top: 25px;

  @media (min-width: $screen-sm-min) {
	padding-left:  30px;
	padding-right: 30px;
  }
}

.page-header {
  color: $brand-primary;
}

.arrow-down-white {

  text-align: center;

  img {
	width:  50px;
	height: auto;
  }
}