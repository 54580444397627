// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

label {
  font-weight: 400;
}

textarea {
  height: 150px;
}

input[type=submit] {
  background-color: $link-color;
  border:           0;
  color:            #fff;
  padding:          10px 30px;
  opacity:          0.9;
  font-size:        16px;
}

input[type=submit]:hover, input[type=submit]:focus {
  opacity: 1;
}

div.wpcf7-response-output {
  margin: 2em 0 1em;
}

.wpcf7-text, .wpcf7-textarea {
  width: 100%;
}