header {

  background:          #fff url("../images/logo-bg.png") no-repeat;
  background-color:    #fff;
  background-position: center top;

}

/*
body.engagement header {

  @media (min-width: $screen-lg-min) {
	height: 270px;
  }

  @media (min-width: $screen-xl-min) {
	height: 370px;
  }
}
*/
.nav-primary {

  margin-bottom: 5px;
  margin-top:    15px;

  @media (min-width: $screen-md-min) {
	margin-bottom: 15px;
	margin-top:    15px;
  }

  @media (min-width: $screen-lg-min) {
	margin-top: 25px;
  }

}

.navbar-brand {
  width:         180px;
  height:        auto;
  padding:       0;
  margin-bottom: 10px;

  @media (min-width: $screen-md-min) {
	width:       300px;
	margin-left: 30px;
  }

  &:hover, &:focus {
	opacity: 1;
  }

}

.navbar-nav {

  @media (min-width: $screen-md-min) {
	margin-top:   10px;
	margin-right: 0;
  }

  & > li {

	font-size:   16px;
	font-weight: 600;

	@media (min-width: $screen-sm-min) {
	  margin-left:  15px;
	  margin-right: 15px;
	}

	&.active {

	  & > a {

		font-weight: 600;
		position:    relative;

		&:after {

		  @media (min-width: $screen-lg-min) {
			position:   absolute;
			top:        100%;
			left:       0;
			width:      100%;
			height:     2px;
			background: #fff;
			content:    '';
			opacity:    1;
		  }

		}
	  }
	}

	& > a, & > a:hover, & > a:focus {
	  color:          $brand-primary;
	  background:     none;
	  padding-bottom: 5px;
	  padding-top:    5px;
	  padding-left:   0;
	  padding-right:  0;
	  opacity:        1;
	}

	&.darken > a {
	  opacity: 0.4;

	  & > a:hover, & > a:focus {
		opacity: 0.4;
	  }
	}

	& > a {
	  font-weight: 600;
	}

	&:not(.active) > a::after {
	  @media (min-width: $screen-lg-min) {
		position:           absolute;
		top:                100%;
		left:               0;
		width:              100%;
		height:             1px;
		background:         $brand-primary;
		content:            '';
		opacity:            0;
		-webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
		-moz-transition:    height 0.3s, opacity 0.3s, -moz-transform 0.3s;
		transition:         height 0.3s, opacity 0.3s, transform 0.3s;
		-webkit-transform:  translateY(-10px);
		-moz-transform:     translateY(-10px);
		transform:          translateY(-10px);
	  }
	}

	& > a:hover::after,
	& > a:focus::after {
	  @media (min-width: $screen-lg-min) {
		height:            2px;
		opacity:           1;
		-webkit-transform: translateY(0px);
		-moz-transform:    translateY(0px);
		transform:         translateY(0px);
	  }
	}

	&.darken > a:hover::after,
	&.darken > a:focus::after {
	  @media (min-width: $screen-lg-min) {
		opacity: 0.4;
	  }
	}

  }

}

.cd-nav-trigger {
  position:    relative;
  display:     block;
  width:       44px;
  height:      30px;
  overflow:    hidden;
  white-space: nowrap;
  /* hide text */
  color:       transparent;
  float:       right;

  &:hover, &:focus {
	color:   transparent;
	opacity: 1;
  }

  span, span::before, span::after {
	/* hamburger icon in CSS */
	position:   absolute;
	display:    inline-block;
	height:     3px;
	width:      24px;
	background: $brand-primary;
  }

  span {
	/* line in the center */
	position:   absolute;
	top:        50%;
	right:      10px;
	margin-top: -2px;
	@include transition(background .3s .3s);
  }

  span::before, span::after {
	/* other 2 lines */
	content:                     '';
	right:                       0;
	/* Force Hardware Acceleration in WebKit */
	@include transform(translateZ(0));
	-webkit-backface-visibility: hidden;
	backface-visibility:         hidden;
	@include transform-origin(0% 50%);
	@include transition(transform .3s .3s);
  }

  span::before {
	/* menu icon top line */
	top: -6px;
  }

  span::after {
	/* menu icon bottom line */
	top: 6px;
  }

  &.nav-is-visible {
	span {
	  /* hide line in the center */
	  background: rgba(#fff, 0);
	}

	span::before, span::after {
	  /* keep visible other 2 lines */
	  background: rgba(#fff, 1);
	}

	span::before {
	  @include transform(translateX(4px) translateY(-3px) rotate(45deg));
	}

	span::after {
	  @include transform(translateX(4px) translateY(2px) rotate(-45deg));
	}
  }

  @media (min-width: $screen-sm-min) {
	display: none;
  }

}