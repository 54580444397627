// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$color-main: #0e2356;
$brand-primary: $color-main;
$brand-secondary: #00A888;

$color-main: #0e2356;
$background-color: #e7e7e7;

$link-color: #08c;

@mixin cssSprite( $spriteVals ) {
  width:               nth($spriteVals, 1);
  height:              nth($spriteVals, 2);
  background-repeat:   no-repeat;
  background-image:    url( #{ nth( $spriteVals, 3 ) } );
  background-position: nth($spriteVals, 4) nth($spriteVals, 5);
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
	background-image: url( #{ nth( $spriteVals, 6 ) } );
	background-size:  $bgiSizeW $bgiSizeH;
  }
}

$SpritePath: '../images/Sprite';
$SpriteURL: $SpritePath + '.png';
$Spritex2URL: $SpritePath + '@2x.png';
$bgiSizeW: 76px;
$bgiSizeH: 76px;
$arrow-right: 30px 22.5px $SpriteURL -3px -7px $Spritex2URL;
$arrow-link-diagonal: 18px 18px $SpriteURL -50px -9px $Spritex2URL;
$arrow-top-small-gray: 15px 15px $SpriteURL -43.5px -38.5px $Spritex2URL;
$arrow-right-small-blue: 22px 17.285714285714285px $SpriteURL -4px -37px $Spritex2URL;